import { useAuthStore } from '@/stores/auth'

export default defineNuxtRouteMiddleware(async (to) => {
    const authStore: any = useAuthStore()
    const notificationStore: any = useNotificationStore();

    const localePath = useLocalePath()

    // If preventNotificationFetch cookie doesn't exists or notification state is null, send request to api
    if(!getCookie('preventNotificationFetch') || !notificationStore.notification){
        notificationStore.retrieveNotification()

        if(authStore.loggedIn){
            // Set cookie with 15 minutes max age
            // If cookie exists -> prevent fetching of notifications
            setCookie('preventNotificationFetch', true, 15, 'minutes');
        }
    }
    
    // If logged in state doesn't exists
    if (!authStore.loggedIn) {
        try {
            // Try to authenticated user
            await authStore.authenticated();

            // Perform redirections after authentication
            if(authStore.loggedIn){
                if(authStore.user.change_password && to?.path !== localePath('new-password')){
                    // Redirect to new password route
                    return navigateTo(localePath('/new-password'));
                }

                // if logged in and url dosn't contain dashboard redirect to dashboard
                if (!to?.path.includes('dashboard')) {
                    return navigateTo(localePath('/dashboard'));
                }
            }
            // if not logged in redirect to log in page
            if (!authStore.loggedIn && to?.path !== localePath('login')) {
                return navigateTo(localePath('/login'));
            }
        } catch (error) {
            // Handle authentication error
            console.error('Authentication error:', error);
            if(to?.path !== localePath('login')) {
                return navigateTo(localePath('/login'));
            }
        }
    } else {
        // If logged in state exists
        if(authStore.user.change_password && to?.path !== localePath('new-password')){
            // Redirect to new password route
            return navigateTo(localePath('/new-password'));
        }
    }
});